@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins, sans-serif', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dot {
  text-align: center;
  height: 19.55vw;
  width: 19.55%;
  margin-left: auto;
  margin-right: auto;
  background-color: #17384c;
  border-radius: 50%
}

.dot_step4 {
  text-align: center;
  height: 12.55vw;
  width: 12.55%;
  margin-left: auto;
  margin-right: auto;
  background-color: #17384c;
  border-radius: 50%
}

.outlined-basic {
  border-radius: 50px !important;
  border-color: #17384c;
}